import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import styles from './Chat.module.scss'
import { io } from 'socket.io-client'
import { useUnit } from 'effector-react'
import { $user } from '../../../store/user'
import SendIcon from './icons/SendIcon'
import AttachmentIcon from './icons/AttachmentIcon'
import { IMessage, IUploadResponse } from '../../../types/message'
import MicIcon from './icons/MicIcon'
import StopIcon from './icons/StopIcon'
import { formatRecordingTime } from '../../../utils/formatTime'
import TrashIcon from './icons/TrashIcon'
import { useNavigate, useSearchParams } from 'react-router-dom'
import MessageList from '../MessageList'
import { useCheckAdmin } from '../../../hooks/useCheckUser'
import ChatList from '../../../data/chatList'

export default function Chat() {
  const [files, setFiles] = useState<File[] | null>(null)
  const [recordingDuration, setRecordingDuration] = useState<number>(0)
  const [timerInterval, setTimerInterval] = useState<number | null>(null)
  const [messages, setMessages] = useState<IMessage[]>([])
  const [inputMessage, setInputMessage] = useState('')
  const socket = io(process.env.REACT_APP_WS_URL)
  const user = useUnit($user)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const slug = searchParams.get('chat') || 'global'
  const [mediaRecorder, setMediaRecorder] = useState<any>()
  const [isRecording, setIsRecording] = useState(false)
  const isAdmin = useCheckAdmin()
  const chatList = ChatList()

  useEffect(() => {
    socket.emit('joinChat', slug)

    socket.on('chatMessages', messages => {
      setMessages(messages)
    })
  }, [slug])

  useEffect(() => {
    if (!isAdmin && slug && /^id\d+$/.test(slug) && slug !== `id${user?.id}`) {
      navigate('?chat=global')
    }
  }, [isAdmin, navigate, slug, user])

  useEffect(() => {
    const handleMessage = ({ data }: { data: IMessage }) => {
      if (data.userId !== user?.id) {
        setMessages(prev => [...prev, data])
      }
    }

    socket.on('message', handleMessage)

    return () => {
      socket.off('message', handleMessage)
    }
  }, [socket])

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sendMessage()
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFiles = Array.from(event.target.files)
      const imageFiles = newFiles.filter(file => file.type.startsWith('image/'))

      setFiles(prevFiles => {
        const existingImageFiles =
          prevFiles?.filter(file => file.type.startsWith('image/')) || []
        const allImageFiles = [...existingImageFiles, ...imageFiles].slice(0, 5) // Не больше 5
        return allImageFiles
      })
    }
  }

  useEffect(() => {
    const handleNewMedia = (data: IMessage) => {
      setMessages(prev => {
        const isDuplicate = prev.some(msg => msg.id === data.id)
        if (!isDuplicate) {
          return [...prev, data]
        }
        return prev
      })
    }

    socket.on('new_media', handleNewMedia)

    return () => {
      socket.off('new_media', handleNewMedia)
    }
  }, [socket])

  const sendMessage = () => {
    const lastMessage = messages[messages.length - 1]
    const newId = lastMessage ? lastMessage.id + 1 : 1

    if (user) {
      if (inputMessage.trim()) {
        const newMessage: IMessage = {
          id: newId,
          userId: user.id,
          chatSlug: slug,
          msg: inputMessage,
          timestamp: new Date(),
        }
        setMessages(prev => [...prev, newMessage])
        socket.emit('message', newMessage)
        setInputMessage('')
      } else if (files && files.length > 0) {
        files.forEach(file => {
          const reader = new FileReader()
          reader.onload = () => {
            const result = reader.result
            const [type] = file.type.split('/')

            if (result) {
              const fileData = {
                id: newId,
                userId: user.id,
                chatSlug: slug,
                type,
                fileName: file.name,
                data: result,
                audioDuration: recordingDuration,
                timestamp: new Date().toISOString(),
              }

              console.log(fileData)

              socket.emit(
                'upload_media',
                fileData,
                (response: IUploadResponse) => {
                  if (response.success) {
                    const newMessage: IMessage = {
                      id: newId,
                      userId: user.id,
                      chatSlug: slug,
                      type,
                      audioDuration: recordingDuration,
                      url: response.url,
                      timestamp: new Date(),
                    }
                    setMessages(prevMessages => [...prevMessages, newMessage])
                    setFiles(null)
                  } else {
                    console.error(response.error)
                  }
                },
              )
            }
          }

          reader.readAsArrayBuffer(file)
        })
      }
    }
  }

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const recorder = new MediaRecorder(stream)
      setMediaRecorder(recorder)

      const chunks: Blob[] = []
      setRecordingDuration(0)

      const intervalId = window.setInterval(() => {
        setRecordingDuration(prevDuration => prevDuration + 1)
      }, 1000)
      setTimerInterval(intervalId)

      recorder.ondataavailable = event => {
        if (event.data.size > 0) {
          chunks.push(event.data)
        }
      }

      recorder.onstop = async () => {
        clearInterval(intervalId)
        setTimerInterval(null)
        await handleRecordingStop(chunks, stream)
      }

      recorder.start()
      setIsRecording(true)

      recorder.onerror = event => {
        console.error('Error recording:', event)
      }
    } catch (error) {
      console.error('Error starting recording:', error)
    }
  }

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop()
    } else {
      console.warn('MediaRecorder is not in the recording state.')
    }

    if (timerInterval) {
      clearInterval(timerInterval)
      setTimerInterval(null)
    }
  }

  const handleRecordingStop = async (chunks: Blob[], stream: MediaStream) => {
    const blob = new Blob(chunks, { type: 'audio/wav' })
    const fileName = 'voice_message.wav'
    const fileData = new File([blob], fileName, { type: 'audio/wav' })

    setFiles(prevFiles => (prevFiles ? [...prevFiles, fileData] : [fileData]))

    stream.getTracks().forEach(track => track.stop())
    setIsRecording(false)
  }

  const handleRemoveFile = (index: number) => {
    setFiles(prevFiles => prevFiles?.filter((_, i) => i !== index) || [])
  }

  const currentChat = chatList.find(chat => chat.slug === slug)

  return (
    <div className={styles.Chat}>
      <div className={styles.Header}>
        <img src={currentChat?.icon} alt={currentChat?.name} />
        {currentChat?.name}
      </div>
      <MessageList messages={messages} />
      {files && files.length > 0 && (
        <div className={styles.AttachedMediaFiles}>
          {files
            .filter(file => file.type.startsWith('image/'))
            .slice(0, 5)
            .map((file, index) => (
              <div key={index} className={styles.AttachedMediaFile}>
                <img
                  src={URL.createObjectURL(file)}
                  alt={`uploaded file ${index + 1}`}
                />
                <button
                  className={styles.RemoveFileButton}
                  onClick={() => handleRemoveFile(index)}
                >
                  X
                </button>
              </div>
            ))}
        </div>
      )}
      <div className={styles.Footer}>
        {isRecording || files?.some(file => file.type.startsWith('audio')) ? (
          <div className={styles.Recording}>
            <span>{formatRecordingTime(recordingDuration)}</span>
          </div>
        ) : (
          <>
            <input
              id="file-upload"
              type="file"
              multiple
              accept=".png, .jpg, .jpeg, .webp, .gif"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <label
              htmlFor="file-upload"
              className={styles.ChatButton}
              aria-label="Прикрепить медиафайл"
            >
              <AttachmentIcon />
            </label>
            <input
              className={styles.Input}
              type="text"
              placeholder="Напишите сообщение"
              value={inputMessage}
              onChange={e => setInputMessage(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </>
        )}
        {!isRecording && files?.some(file => file.type.startsWith('audio')) ? (
          <>
            <button
              className={styles.ChatButton}
              onClick={() => setFiles(null)}
              aria-label="Удалить голосовое сообщение"
            >
              <TrashIcon />
            </button>
            <button
              className={styles.ChatButton}
              onClick={sendMessage}
              aria-label="Отправить сообщение"
            >
              <SendIcon />
            </button>
          </>
        ) : inputMessage.length > 0 || files ? (
          <button
            className={styles.ChatButton}
            onClick={sendMessage}
            aria-label="Отправить сообщение"
          >
            <SendIcon />
          </button>
        ) : isRecording ? (
          <button
            className={styles.ChatButton}
            onClick={stopRecording}
            aria-label="Остановить запись голосового сообщения"
          >
            <StopIcon />
          </button>
        ) : (
          <button
            className={styles.ChatButton}
            onClick={startRecording}
            aria-label="Начать запись голосового сообщения"
          >
            <MicIcon />
          </button>
        )}
      </div>
    </div>
  )
}
