import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import './assets/styles/globals.scss'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import Toastify from './components/elements/Toastify'
import { MessageModalProvider } from './context/MessageModalContext'
import { ImageModalProvider } from './context/ImageModalContext'
import { MediaPreviewProvider } from './context/MediaPreviewContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <BrowserRouter>
      <MessageModalProvider>
        <ImageModalProvider>
          <MediaPreviewProvider>
            <App />
            <Toastify />
          </MediaPreviewProvider>
        </ImageModalProvider>
      </MessageModalProvider>
    </BrowserRouter>
  </StrictMode>,
)
