import { createEffect } from 'effector'
import api from './axiosClient'

export const login = createEffect(
  async ({
    url,
    username,
    password,
  }: {
    url: string
    username: string
    password: string
  }) => {
    const { data } = await api.post(url, { username, password })

    return { data }
  },
)

export const logoutFx = createEffect(async ({ url }: { url: string }) => {
  const { data } = await api.post(url)

  return { data }
})
