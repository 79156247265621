import { useEffect, useState } from 'react'
import Backdrop from '../../../elements/Backdrop'
import styles from '../styles/Modal.module.scss'
import { useCheckPermission } from '../../../../hooks/useCheckPermission'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { login } from '../../../../api/auth'
import { setUser } from '../../../../store/user'

export default function LoginModal({
  message,
  value,
  setValue,
  setHasPermission,
}: {
  message: string
  value: string
  setValue: (value: string) => void
  setHasPermission: (hasPermission: boolean) => void
}) {
  const [show, setShow] = useState(true)
  const [enterCodeMode, setEnterCodeMode] = useState(true)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isValid = useCheckPermission(value)

  const submitCode = () => {
    if (isValid) {
      setShow(false)
      setHasPermission(true)
    } else {
      toast.error('Код недействительный')
      setHasPermission(false)
    }
  }

  const onLogin = async () => {
    try {
      const { data } = await login({
        url: '/auth/login',
        username: username,
        password: password,
      })

      localStorage.setItem('token', data.token)
      setUser(data.user)

      toast.success('Авторизация прошла успешно')

      window.location.reload()
      navigate('/profile')
    } catch (error) {
      console.error(error)
      toast.error('Произошла ошибка')
    }
  }

  return show ? (
    <>
      <Backdrop />
      {enterCodeMode ? (
        <div className={`${styles.Modal} ${styles.EnterCodeModal}`}>
          <h3 className={styles.Message}>{message}</h3>
          <input
            className={styles.Input}
            placeholder="Введите ваш код"
            value={value}
            onChange={e => setValue(e.target.value)}
          />
          <button
            className={`${styles.NextButton} ${!value ? styles.NextButtonDisabled : ''}`}
            onClick={submitCode}
          >
            Далее
          </button>
          <button
            className={styles.VipButton}
            onClick={() => setEnterCodeMode(false)}
          >
            VIP
          </button>
        </div>
      ) : (
        <div className={`${styles.Modal} ${styles.LoginModal}`}>
          <h3 className={styles.Message}>
            Если у тебя есть аккаунт, ты можешь войти в него!
          </h3>
          <input
            type="text"
            className={styles.LoginInput}
            placeholder="Логин"
            onChange={e => setUsername(e.target.value)}
          />
          <input
            type="password"
            className={styles.LoginInput}
            placeholder="Пароль"
            onChange={e => setPassword(e.target.value)}
          />
          <button
            className={styles.LoginButton}
            onClick={onLogin}
            disabled={username.length <= 3 || password.length <= 3}
          >
            Войти
          </button>
          <button
            className={styles.EnterCodeButton}
            onClick={() => setEnterCodeMode(true)}
          >
            Ввести код
          </button>
        </div>
      )}
    </>
  ) : (
    <></>
  )
}
